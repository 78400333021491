import smoothScroll from 'smooth-scroll';

export const Buttons = {
    initButtons() {
        const scroll = new smoothScroll();
        const contactUsButton = document.getElementById('contact-us');
        const discoverButton = document.getElementById('discover');

        contactUsButton.addEventListener('click', function (e) {
            scroll.animateScroll(document.getElementById('footer'));
        });

        discoverButton.addEventListener('click', function (e) {
            scroll.animateScroll(document.querySelector('#about'), null,{
                offset: 60,
            });
        });
    }
}
