export const MobileMenu = {
    initMobile() {
        const burger = document.querySelector('.hamburger');
        const navbar = document.getElementById('navbar');
        const mobileMenu = document.querySelector('.mobile-menu');

        burger.addEventListener('click', function (e) {
            this.classList.toggle('is-active');
            mobileMenu.classList.toggle('mobile-menu--visible');
            if (!navbar.classList.contains('navbar--scrolled')) {
                navbar.classList.add('navbar--scrolled');
            }
            if (!this.classList.contains('is-active') && window.scrollY < 180){
                navbar.classList.remove('navbar--scrolled');
            }
        })
    },

    initMobileMenuHideOnLinkClick() {
        document.querySelectorAll('.mobile-menu a').forEach(function (elem) {
            elem.addEventListener('click', function () {
                document.querySelector('.hamburger').classList.remove('is-active');
                document.querySelector('.mobile-menu').classList.remove('mobile-menu--visible');
            })
        });
    }
}
