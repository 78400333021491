import smoothScroll from 'smooth-scroll';

export const Navbar = {
    initScrollListener() {
        const navbar = document.getElementById('navbar');
        const burger = document.querySelector('.hamburger');
        let smallDevice = false;

        // checking if page is scrolled on page load. If so - adding scrolled class
        if (window.scrollY > 180 && navbar.classList !== 'navbar--scrolled') {
            navbar.classList.add('navbar--scrolled');
        }
        // checking if device is in not very tall. If so - adding scrolled class and switch a flag on
        if (window.innerHeight < 700 && navbar.classList !== 'navbar--scrolled') {
            navbar.classList.add('navbar--scrolled');
            smallDevice = true;
        }

        window.addEventListener('scroll', function (e) {
            // adding active class to links for active section
            // if (window.scrollY < 600) {
            //     document.querySelectorAll('a[href*="#about"]').forEach(function (elem) {
            //         elem.classList.remove('active');
            //     });
            // }
            // if (window.scrollY > 600 && window.scrollY < 1600) {
            //     document.querySelectorAll('a[href*="#clients"]').forEach(function (elem) {
            //         elem.classList.remove('active');
            //     });
            //     document.querySelectorAll('a[href*="#portfolio"]').forEach(function (elem) {
            //         elem.classList.remove('active');
            //     });
            //     document.querySelector('a[href*="#contact"]').classList.remove('active');

            //     document.querySelectorAll('a[href*="#about"]').forEach(function (elem) {
            //         elem.classList.add('active');
            //     });
            // }
            // if (window.scrollY > 1600 && window.scrollY < 2000) {
            //     document.querySelectorAll('a[href*="#about"]').forEach(function (elem) {
            //         elem.classList.remove('active');
            //     });
            //     document.querySelectorAll('a[href*="#portfolio"]').forEach(function (elem) {
            //         elem.classList.remove('active');
            //     });
            //     document.querySelector('a[href*="#contact"]').classList.remove('active');

            //     document.querySelectorAll('a[href*="#clients"]').forEach(function (elem) {
            //         elem.classList.add('active');
            //     });
            // }
            // if (window.scrollY > 2000 && window.scrollY < 2140) {
            //     document.querySelectorAll('a[href*="#about"]').forEach(function (elem) {
            //         elem.classList.remove('active');
            //     });
            //     document.querySelectorAll('a[href*="#clients"]').forEach(function (elem) {
            //         elem.classList.remove('active');
            //     });
            //     document.querySelector('a[href*="#contact"]').classList.remove('active');

            //     document.querySelectorAll('a[href*="#portfolio"]').forEach(function (elem) {
            //         elem.classList.add('active');
            //     });
            // }
            // if (window.scrollY > 2140) {
            //     document.querySelectorAll('a[href*="#about"]').forEach(function (elem) {
            //         elem.classList.remove('active');
            //     });
            //     document.querySelectorAll('a[href*="#clients"]').forEach(function (elem) {
            //         elem.classList.remove('active');
            //     });
            //     document.querySelectorAll('a[href*="#portfolio"]').forEach(function (elem) {
            //         elem.classList.remove('active');
            //     });

            //     document.querySelector('a[href*="#contact"]').classList.add('active');
            // }
            // if (document.scrollY < 2140) {
            //     document.querySelector('a[href*="#contact"]').classList.remove('active');
            // }

            if (window.scrollY > 180 && navbar.classList !== 'navbar--scrolled') {
                navbar.classList.add('navbar--scrolled');
                return;
            }

            if (window.scrollY < 180 && navbar.classList == 'navbar--scrolled' && !burger.classList.contains('is-active') && !smallDevice) {
                navbar.classList.remove('navbar--scrolled');
            }
        });
    },

    initSmoothScroll() {
        const scroll = new smoothScroll('a[href*="#"]', {
            offset: 60,
        });
    }
}
