/* CSS */

// importing normalize
import './node_modules/normalize.css/normalize.css';

// importing styles
import './scss/index.scss';

/* custom JS */
import {
    Navbar
} from './js/navbar';
import {
    Buttons
} from './js/buttons';
import {
    MobileMenu
} from './js/mobile-menu';

function fireApp() {
    Navbar.initScrollListener();
    Navbar.initSmoothScroll();
    Buttons.initButtons();
    MobileMenu.initMobile();
    MobileMenu.initMobileMenuHideOnLinkClick();
}

if (document.readyState !== 'loading') {
    fireApp();
} else {
    document.addEventListener('DOMContentLoaded', fireApp);
}
